<template>
  <v-container v-resize="onResize">
    <BaseTitlePage :title="title">
      <template v-slot:button>
        <div class="b-pages__content--resume">
          <span>Última atualização: {{ formatDate(model.updatedAt) }}</span>
          <span class="d-flex align-center">
            <v-tooltip bottom v-if="model.canDemand">
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  class="message-icons color-red cursor-pointer mr-3"
                  @click="isDemanded = true"
                >
                  <i class="fa-solid fa-right-left"></i>
                </span>
              </template>
              <span>Demandar chamado</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  Status:
                  <span
                    :class="[
                      canChangeStatus && 'underline-hover',
                      getColor(model.situation)
                    ]"
                    @click="changeStatus"
                  >
                    {{ model.situation }}
                  </span>
                </span>
              </template>
              <span>
                {{
                  canChangeStatus
                    ? 'Alterar status do chamado'
                    : 'Status do chamado'
                }}
              </span>
            </v-tooltip>
          </span>
        </div>
      </template>
    </BaseTitlePage>

    <ChatHeader />

    <div class="message-content" v-if="isTabVisible && model && model.id">
      <v-row>
        <v-col cols="12" xl="8" lg="8" md="8" sm="7" class="border-right pa-0">
          <v-tabs
            show-arrows
            :class="['b-tab-horizontal', 'message-content--tabs', hideTab]"
            v-model="tab"
          >
            <template v-for="(header, index) in tabs">
              <v-tab
                v-if="header.visible"
                :href="`#${header.href}`"
                :key="index"
              >
                <v-badge
                  dot
                  class="badge-notify"
                  color="red"
                  :value="header.notifications"
                  :content="header.notifications"
                >
                  <span>{{ header.title }}</span>
                </v-badge>
              </v-tab>
            </template>
          </v-tabs>

          <v-tabs-items v-model="tab" class="message-content--tabs__items">
            <template v-for="(content, index) in tabs">
              <v-tab-item
                v-if="content.visible"
                :value="content.href"
                :key="index"
              >
                <component
                  v-if="content.href === tab"
                  :is="content.component"
                  :isDemandedTab="content.isDemandedTab"
                  :ticketId="$route.params.id"
                />
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="5" v-if="innerWidth >= 600">
          <ChatFiles :ticketId="$route.params.id" />
        </v-col>
      </v-row>
    </div>

    <v-row class="mt-1">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="text-right">
        <BaseButton
          id="btn-back"
          name="btn-back"
          color="primary"
          title="Voltar"
          outlined
          @click="back"
        />
        <BaseButton
          id="btn-close-ticket"
          name="btn-close-ticket"
          color="success"
          title="Fechar chamado"
          v-if="hideCloseTicket"
          @click="isResolve = true"
        />
        <BaseButton
          id="btn-return-ticket"
          name="btn-return-ticket"
          color="success"
          title="Devolver chamado"
          v-if="hideReturnTicket"
          @click="isReturnCalled = true"
        />
      </v-col>
    </v-row>

    <DialogResolve
      v-if="isResolve"
      :dialog="isResolve"
      :ticketId="model.id"
      @closeModal="closeModal"
    />

    <DialogReturn
      v-if="isReturnCalled"
      :dialog="isReturnCalled"
      :ticketId="model.id"
      @closeModal="closeModal"
    />

    <DialogDemanded
      v-if="isDemanded"
      :dialog="isDemanded"
      :ticketId="model.id"
      @closeModal="closeModal"
    />

    <DialogEditSituation
      v-if="isEditSituation"
      :dialog="isEditSituation"
      :ticketId="model.id"
      :currentStatus="model.situation"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { supportMixin } from '@/mixins/support';
import { getColor } from '@/helpers/utils';
import { formatDate } from '@/helpers/formatting';

export default {
  mixins: [supportMixin],

  components: {
    ChatHeader: () => import('@/components/pages/supports/chat-header'),
    ChatMessages: () => import('@/components/pages/supports/chat-messages'),
    ChatFiles: () => import('@/components/pages/supports/chat-files'),
    DialogResolve: () =>
      import('@/components/pages/supports/dialogs/dialog-resolve'),
    DialogReturn: () =>
      import('@/components/pages/supports/dialogs/dialog-return'),
    DialogDemanded: () =>
      import('@/components/pages/supports/dialogs/dialog-demanded'),
    DialogEditSituation: () =>
      import('@/components/pages/supports/dialogs/dialog-edit-situation')
  },

  data: () => ({
    isEditSituation: false,
    isDemanded: false,
    isTabVisible: false,
    innerWidth: 0,
    isResolve: false,
    isReturnCalled: false,
    tab: null,
    tabs: [
      {
        href: 'tab-1',
        title: 'Cliente',
        component: 'ChatMessages',
        visible: true,
        notifications: 0,
        isDemandedTab: false
      },
      {
        href: 'tab-2',
        title: 'Demandado',
        component: 'ChatMessages',
        visible: true,
        notifications: 0,
        isDemandedTab: true
      }
    ]
  }),

  computed: {
    title() {
      const { ticketId } = this.$route.params;

      return `Consulta do chamado <strong class="primary--text">${ticketId}</strong>`;
    },

    model() {
      const result = this.$store.getters['support/getById'];

      this.enableTab(result);

      return result;
    },

    hideReturnTicket() {
      return !this.model.readonly && this.model.isDemandedUser;
    },

    hideCloseTicket() {
      return (
        !this.model.readonly &&
        !this.model.isDemandedUser &&
        !this.model.isCustomerUser &&
        (this.model.situation === 'Novo' ||
          this.model.situation === 'Em andamento')
      );
    },

    canChangeStatus() {
      return (
        !this.model.readonly &&
        (this.isResponsible || this.model.isDemandedUser) &&
        (this.model.situation === 'Em andamento' ||
          this.model.situation === 'Aguardando operadora')
      );
    },

    isResponsible() {
      return (
        this.model.isDemandedUser === false &&
        this.model.isCustomerUser === false
      );
    },

    hideTab() {
      if (
        this.model.isDemandedUser ||
        this.model.isCustomerUser ||
        (!this.model.hasAlreadyBeenDemandedUser && this.isResponsible)
      ) {
        return 'd-none';
      } else {
        return '';
      }
    }
  },

  created() {
    this.search();
  },

  methods: {
    formatDate,
    getColor,

    back() {
      this.$router.push({ name: 'suportes' });

      this.$store.commit('support/setById', {});
      this.$store.commit('support/setFiles', null);
      this.$store.commit('support/setMessages', null);
    },

    closeModal(redirect) {
      this.isResolve = false;
      this.isReturnCalled = false;
      this.isDemanded = false;
      this.isEditSituation = false;

      if (redirect) {
        this.back();
      }
    },

    onResize() {
      this.innerWidth = window.innerWidth;
    },

    search() {
      const { id } = this.$route.params;

      if (id) {
        this.getSupportById(id);
      }
    },

    enableTab(params) {
      if (params.isDemandedUser) {
        this.tab = 'tab-2';
      } else if (params.isCustomerUser) {
        this.tab = 'tab-1';
      } else {
        this.tab = 'tab-1';
      }

      this.isTabVisible = true;
    },

    changeStatus() {
      if (this.canChangeStatus) {
        this.isEditSituation = true;
      } else {
        return;
      }
    }
  }
};
</script>
