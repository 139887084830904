import { SupportService } from '@/services/api/support';

export const supportMixin = {
  methods: {
    async getSupportById(id) {
      try {
        const supportService = new SupportService();
        const { status, data } = await supportService.getById(id);

        if (status === 200) {
          this.$store.commit('support/setById', data);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async getMessages(params) {
      try {
        const supportService = new SupportService();
        const { status, data } = await supportService.getMessages(params);

        if (status === 200) {
          this.$store.commit('support/setMessages', data);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async getFiles(params) {
      try {
        const supportService = new SupportService();
        const { status, data } = await supportService.getFiles(params);

        if (status === 200) {
          this.$store.commit('support/setFiles', data);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
